import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, TextField } from '@mui/material';
import { intentionallyFloatingPromiseReturn } from 'core/utils/commonUtils';
import PageHeaderText from 'layout/pages/PageHeaderText';
import PageHeaderWrapper from 'layout/pages/PageHeaderWrapper';
import { debounce, isNil } from 'lodash-es';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { date, object, string } from 'yup';
import DatePickerElement from './DatePickerElement';

export interface PayoutsFilterData {
  filterText?: string;
  fromDate: Date | null;
  toDate: Date | null;
}

export interface PayoutsPageHeaderProps {
  filterTextInit?: string;
  fromDateInit: Date | null;
  toDateInit: Date | null;
  title: string;
  onFilterChanged: (data: PayoutsFilterData) => void;
}

const schema = object<PayoutsFilterData>({
  filterText: string().max(300).nullable(),
  fromDate: date().nullable(),
  toDate: date().nullable(),
});

export default function PayoutsPageHeader(props: PayoutsPageHeaderProps): JSX.Element {
  const { onFilterChanged, filterTextInit, fromDateInit, toDateInit, title } = props;

  const formContext = useForm<PayoutsFilterData>({
    mode: 'onChange',
    defaultValues: {
      filterText: filterTextInit ?? '',
      fromDate: fromDateInit,
      toDate: toDateInit,
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const debouncedCallback = debounce((_value, { type }) => {
      if (type === 'change' && !isNil(onFilterChanged)) {
        intentionallyFloatingPromiseReturn(formContext.handleSubmit(onFilterChanged))();
      }
    }, 400);

    const subscription = formContext.watch(debouncedCallback);

    return () => {
      subscription.unsubscribe();
      debouncedCallback.cancel();
    };
  }, [formContext, onFilterChanged]);

  return (
    <PageHeaderWrapper>
      <PageHeaderText align='center' text={title} />

      <Stack component={'form'} spacing={1} direction='row' alignItems='center'>
        <Controller
          name='filterText'
          control={formContext.control}
          render={({ field: { value, onChange } }) => (
            <TextField
              value={value}
              onChange={onChange}
              placeholder='Поиск по номеру заказа'
              size='small'
              type='search'
              sx={{ width: '250px' }}
            />
          )}
        />

        <DatePickerElement
          name='fromDate'
          control={formContext.control}
          placeholder='Дата от'
        />

        <DatePickerElement
          name='toDate'
          control={formContext.control}
          placeholder='Дата до'
        />
      </Stack>
    </PageHeaderWrapper>
  );
}
