import routes from 'config/routesConfig';
import useCurrentRoute from 'core/hooks/useCurrentRoute';
import { isEmpty, isNil } from 'lodash';
import { generatePath, Navigate, useParams } from 'react-router-dom';

const RouteOrderEditPageTabGuard = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const requiredTabs = [
    'common',
    'actions',
    'projects',
    'photos',
    'files',
    'payments',
    'payouts',
    'documents',
  ];

  const currentRoute = useCurrentRoute(routes.routes);

  const { tab } = useParams();

  if (
    (isNil(tab) || isEmpty(tab) || !requiredTabs.includes(tab)) &&
    !isNil(currentRoute)
  ) {
    const redirectRoute = generatePath(currentRoute.route.path ?? '', {
      ...currentRoute?.params,
      tab: 'common',
    });

    return <Navigate to={redirectRoute} replace />;
  }

  return children;
};

export default RouteOrderEditPageTabGuard;
