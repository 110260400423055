import { string, number } from 'yup';
import type { NumberSchema } from 'yup';

/**
 * Функция, создающая валидатор, проверяющий строку на наличие только цифр.
 *
 * @function
 * @returns {object} Валидатор Yup для проверки на наличие только цифр.
 */
export const numericValidator = string().matches(
  /^\d*\.?\d*(e-?\d+)?$/,
  'должно быть числом'
);

/**
 * Функция, создающая валидатор, проверяющий строку на соответствие следующим критериям:
 * минимальная длина - 6 символов, максимальная длина - 32 символа,
 * строка должна состоять из латинских букв, цифр и специальных символов !@#$%*,
 * и строка должна содержать как минимум одну букву и одну цифру.
 *
 * @function
 * @returns {object} Валидатор Yup для проверки пароля.
 */
export const passwordValidator = string()
  .min(6)
  .max(32)
  .matches(
    /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\d!@#$%*]*)$/,
    'пароль должен состоять из латинских букв, цифр и спецсимволов !@#$%*'
  )
  .matches(/^(?=.*[0-9])(?=.*[a-zA-Z]).*$/, 'пароль должен содержать буквы и цифры');

/**
 * Функция, создающая валидатор, проверяющий строку на соответствие следующим критериям:
 * строка должна начинаться с символа "7" и содержать 10 цифр после него.
 *
 * @function
 * @returns {object} Валидатор Yup для проверки номера телефона.
 */
export const phoneValidator = string().matches(
  /^7(\d{10})$/,
  'некорректный номер телефона'
);

/**
 * Создает валидатор Yup для проверки количества десятичных знаков в числе.
 *
 * @param {number} decimals - Максимальное количество разрешенных десятичных знаков.
 * @param {string} message - Сообщение об ошибке (опционально).
 * @returns {NumberSchema} Схема валидации Yup.
 */
export const decimalPlaces = (decimals: number, message?: string): NumberSchema =>
  number().test({
    name: 'decimal-places',
    exclusive: true,
    params: { decimals },
    message: message ?? `Значение должно иметь не более ${decimals} знаков после запятой`,
    test: (value: number | undefined | null) => {
      if (value === undefined || value === null) return true;
      const regex = new RegExp(`^-?\\d+(\\.\\d{1,${decimals}})?$`);
      return regex.test(value.toString());
    },
  });
