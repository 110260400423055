import { blueGrey } from '@mui/material/colors';
import { ruRU as coreRuRU } from '@mui/material/locale';
import { alpha, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { ruRU as datagridRuRU } from '@mui/x-data-grid/locales';
import { ruRU as datePickersRuRU } from '@mui/x-date-pickers/locales';
import type {} from '@mui/x-data-grid/themeAugmentation';
import type {} from '@mui/x-date-pickers/themeAugmentation';

let theme = createTheme(
  {
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: (props) => ({
            '&:has([readonly])': {
              backgroundColor: alpha(
                '#fffbd0',
                props.theme.palette.action.disabledOpacity
              ),
            },
          }),
        },
      },
      MuiDataGrid: {
        defaultProps: {
          pageSizeOptions: [10, 25, 50, 100],
          initialState: {
            pagination: {
              paginationModel: {
                page: 0,
                pageSize: 25,
              },
            },
          },
        },
      },
    },
    palette: {
      background: {
        default: blueGrey[50],
      },
    },
  },
  coreRuRU,
  datagridRuRU,
  datePickersRuRU
);

theme = responsiveFontSizes(theme);

export default theme;
