import { nullable2undefinable } from 'core/utils/commonUtils';
import { http } from 'core/utils/httpClient';
import { mapOrderShortInfo } from './orderService';
import type { CreateOrUpdateOrderPayoutRequest } from './dtoModels/order/createOrUpdateOrderPayoutRequest';
import type { GetOrderPayoutsRequest } from './dtoModels/order/getOrderPayoutsRequest';
import type { OrderPayoutInfoResponse } from './dtoModels/order/orderPayoutInfoResponse';
import type { PaginatedList } from './dtoModels/paginatedList';
import type { OrderPayoutInfo } from 'models/order/orderPayoutInfo';

export const mapOrderPayout = (source: OrderPayoutInfoResponse): OrderPayoutInfo => ({
  ...source,
  dateCreate: new Date(source.dateCreate),
  datePayout: new Date(source.datePayout),
  userCreated: source.userCreated,
  userPayout: nullable2undefinable(source.userPayout),
  order: mapOrderShortInfo(source.order),
});

export const mapOrderPayouts = (source: OrderPayoutInfoResponse[]): OrderPayoutInfo[] =>
  source.map((ps) => mapOrderPayout(ps));

export async function getOrderPayoutsAsync(orderId: number): Promise<OrderPayoutInfo[]> {
  const { data } = await http.get<OrderPayoutInfoResponse[]>(`orders/${orderId}/payouts`);

  return mapOrderPayouts(data);
}

export async function createOrderPayoutAsync(
  orderId: number,
  request: CreateOrUpdateOrderPayoutRequest
): Promise<OrderPayoutInfo> {
  const { data } = await http.post<OrderPayoutInfoResponse>(
    `orders/${orderId}/payouts`,
    request
  );
  return mapOrderPayout(data);
}

export async function updateOrderPayoutAsync(
  orderId: number,
  payoutId: number,
  request: CreateOrUpdateOrderPayoutRequest
): Promise<OrderPayoutInfo> {
  const { data } = await http.put<OrderPayoutInfoResponse>(
    `orders/${orderId}/payouts/${payoutId}`,
    request
  );
  return mapOrderPayout(data);
}

export async function getAllOrderPayoutsAsync(
  request: GetOrderPayoutsRequest
): Promise<PaginatedList<OrderPayoutInfo>> {
  const params = new URLSearchParams({
    pageSize: request.pageSize.toString(),
    pageIndex: request.pageIndex.toString(),
  });
  if (request.filterText) params.append('filterText', request.filterText);
  if (request.from) params.append('from', request.from.toISOString());
  if (request.to) params.append('to', request.to.toISOString());

  const { data } = await http.get<PaginatedList<OrderPayoutInfoResponse>>(
    `order-payouts${params.size > 0 ? `?${params.toString()}` : ''}`
  );
  return {
    ...data,
    items: mapOrderPayouts(data.items),
  };
}
