import { enum2NumberArray } from 'core/utils/commonUtils';
import { phoneValidator, numericValidator } from 'core/utils/validators/common';
import {
  kppValidator,
  ogrnValidator,
  bankAccount,
  entityInnValidator,
  individualInnValidator,
} from 'core/utils/validators/requisite';
import dayjs from 'dayjs';
import { isEmpty, startCase } from 'lodash';
import { ClientTypeEnum } from 'models/enums/clientTypeEnum';
import { date, number, object, ref, string } from 'yup';
import type ClientFormData from './clientFormData';

const minDocDateOfIssue = dayjs().subtract(50, 'year').toDate();
const maxDocDateOfIssue = new Date();

const clientFormSchema = object<ClientFormData>({
  surname: string()
    .max(300)
    .transform((v) => (isEmpty(v) ? '' : startCase(v))),
  name: string()
    .when('type', {
      is: (type: ClientTypeEnum) => type === ClientTypeEnum.Entity,
      then: () => string(),
      otherwise: () => string().required(),
    })
    .max(300)
    .transform((v) => (isEmpty(v) ? '' : startCase(v))),
  patronymic: string()
    .max(300)
    .transform((v) => (isEmpty(v) ? '' : startCase(v))),
  fullName: string()
    .when('type', {
      is: (type: ClientTypeEnum) => type === ClientTypeEnum.Entity,
      then: () => string().required(),
      otherwise: () => string().notRequired(),
    })
    .max(300),
  phone: string()
    .ensure()
    .when({
      is: (phone: string) => !isEmpty(phone),
      then: () => phoneValidator,
      otherwise: () => string(),
    }),
  email: string().email().notRequired(),
  registrationAddress: string().max(500).ensure(),
  type: number().oneOf(enum2NumberArray(ClientTypeEnum)).required(),

  docDateOfIssue: date()
    .max(maxDocDateOfIssue)
    .min(minDocDateOfIssue)
    // .typeError('Start date is required')
    //  .isType(Partial<Dayjs>)
    .notRequired(),
  docIssuedBy: string().max(500).notRequired(),
  docNumber: numericValidator.max(6).notRequired(),
  docSeries: numericValidator.max(4).notRequired(),

  entityAddress: string().max(500).ensure(),
  inn: string().when('type', {
    is: (v: ClientTypeEnum) => v === ClientTypeEnum.Entity,
    then: () => entityInnValidator,
    otherwise: (schema) =>
      schema.when('type', {
        is: (v: ClientTypeEnum) => v === ClientTypeEnum.IndividualEntrepreneur,
        then: () => individualInnValidator,
        otherwise: () => string(),
      }),
  }),
  kpp: kppValidator.ensure(),
  ogrn: ogrnValidator.ensure(),
  paymentAccount: string()
    .ensure()
    .when('bankBIC', {
      is: (bankBIC: string) => !isEmpty(bankBIC),
      then: () => bankAccount.paymentAccountValidator(ref('bankBIC')),
      otherwise: () => string().max(20),
    }),
  managementFullName: string().max(1000).ensure(),

  bankBIC: bankAccount.bicValidator.ensure(),
  bankName: string().ensure().max(1000),
  bankINN: entityInnValidator.ensure(),
  bankKPP: kppValidator.ensure(),
  bankCorrespondentAccount: string()
    .ensure()
    .when('bankBIC', {
      is: (bankBIC: string) => !isEmpty(bankBIC),
      then: () => bankAccount.correspondentAccountValidator(ref('bankBIC')),
      otherwise: () => string().max(20),
    }),
}).required();

export default clientFormSchema;
