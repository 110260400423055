import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { Controller } from 'react-hook-form';
import type { Control, FieldValues, Path } from 'react-hook-form';

interface DatePickerElementProps<T extends FieldValues> {
  name: string;
  control: Control<T>;
  placeholder: string;
}

export default function DatePickerElement<T extends FieldValues>({
  name,
  control,
  placeholder,
}: DatePickerElementProps<T>): JSX.Element {
  return (
    <Controller
      name={name as Path<T>}
      control={control}
      render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
        <DatePicker
          {...field}
          value={!isNil(value) ? dayjs(value) : null}
          onChange={(newValue) => {
            const date = newValue?.toDate() ?? null;
            onChange(date);
          }}
          slotProps={{
            field: {
              clearable: true,
              onClear: () => {
                onChange(null);
              },
            },
            textField: {
              size: 'small',
              placeholder,
              error: !!error,
              helperText: error?.message,
            },
          }}
        />
      )}
    />
  );
}
