import GridCellExpand from 'core/components/GridCellExpand';
import { currencyFormatter } from './commonUtils';
import type { GridColTypeDef, GridRenderCellParams } from '@mui/x-data-grid';

export const currencyCol: GridColTypeDef = {
  type: 'number',
  valueFormatter: (value) => currencyFormatter.format(Number(value)),
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function renderCellExpand(params: GridRenderCellParams<any, string>): JSX.Element {
  return (
    <GridCellExpand value={params.value ?? ''} width={params.colDef.computedWidth} />
  );
}
