import { getAllOrderPayoutsAsync } from 'services/orderPayoutService';
import useSWR from 'swr';
import type { AxiosError } from 'axios';
import type { GetOrderPayoutsRequest } from 'services/dtoModels/order/getOrderPayoutsRequest';
import type { SWRResponse } from 'swr';

export const SWR_PAYOUTS_ENDPOINT = 'payouts';

type SWRPayoutsData = Awaited<ReturnType<typeof getAllOrderPayoutsAsync>>;

const usePayouts = (
  request: GetOrderPayoutsRequest,
  shouldFetch = true
): SWRResponse<SWRPayoutsData, AxiosError> =>
  useSWR(
    shouldFetch ? [SWR_PAYOUTS_ENDPOINT, request] : null,
    async ([_, params]) => await getAllOrderPayoutsAsync(params)
  );

export default usePayouts;
