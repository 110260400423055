import { nullable2undefinable } from 'core/utils/commonUtils';
import { http } from 'core/utils/httpClient';
import { mapClient } from './clientService';
import type { CreateOrUpdateOrderRequest } from './dtoModels/order/createOrUpdateOrderRequest';
import type { GetOrdersRequest } from './dtoModels/order/getOrdersRequest';
import type { OrderResponse } from './dtoModels/order/orderResponse';
import type { OrderShortInfoResponse } from './dtoModels/order/orderShortInfoResponse';
import type { UpdateOrdersStateRequest } from './dtoModels/order/updateOrdersStateRequest';
import type { PaginatedList } from './dtoModels/paginatedList';
import type { Paginator } from './dtoModels/paginator';
import type { Order } from 'models/order/order';
import type { OrderShortInfo } from 'models/order/orderShortInfo';

export const mapOrders = (source: OrderResponse[]): Order[] =>
  source.map((os) => mapOrder(os));

export const mapOrder = (source: OrderResponse): Order => ({
  ...source,
  dateCreate: new Date(source.dateCreate),
  client: mapClient(source.client),
  action: nullable2undefinable(source.action),
  geolocation: nullable2undefinable(source.geolocation),
});

export const mapOrderShortInfo = (source: OrderShortInfoResponse): OrderShortInfo => ({
  ...source,
  dateCreate: new Date(source.dateCreate),
  geolocation: nullable2undefinable(source.geolocation),
});

export async function getOrdersAsync(
  request: GetOrdersRequest & Paginator
): Promise<PaginatedList<Order>> {
  const { data } = await http.get<PaginatedList<OrderResponse>>('orders', {
    params: request,
  });

  return { ...data, items: mapOrders(data.items) };
}

export async function getOrderDetailAsync(orderId: number): Promise<Order> {
  const { data } = await http.get<OrderResponse>(`orders/${orderId}`);

  return mapOrder(data);
}

export async function createOrderAsync(
  request: CreateOrUpdateOrderRequest
): Promise<Order> {
  const { data } = await http.post<OrderResponse>('orders', request);
  return mapOrder(data);
}

export async function updateOrderAsync(
  orderId: number,
  request: CreateOrUpdateOrderRequest
): Promise<Order> {
  const { data } = await http.put<OrderResponse>(`orders/${orderId}`, request);
  return mapOrder(data);
}

export async function setOrderState(request: UpdateOrdersStateRequest): Promise<void> {
  await http.put<OrderResponse>(`orders/state`, request);
}
