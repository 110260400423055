import { defineAbility } from '@casl/ability';
import { UserRoleEnum } from 'models/enums/userRoleEnum';
import type { ForcedSubject, MongoAbility } from '@casl/ability';
import type AccountData from 'models/accountData';
import type { OrderProject, ProjectProductMarkup } from 'models/order/orderProject';

type ForcedInterface<T, S extends AbilitySubjectsStr> = T & ForcedSubject<S>;

export type AbilityActions = 'create' | 'read' | 'update' | 'delete';
export type AbilitySubjectsStr =
  | 'OrderProject'
  | 'ProjectProductMarkup'
  | 'Products'
  | 'Payouts'
  | 'Users'
  | 'Clients'
  | 'Payments';
export type AbilitySubjects =
  | OrderProject
  | ForcedInterface<OrderProject, 'OrderProject'>
  | ProjectProductMarkup
  | ForcedInterface<ProjectProductMarkup, 'ProjectProductMarkup'>
  | AbilitySubjectsStr;
export type AppAbility = MongoAbility<[AbilityActions, AbilitySubjects]>;

type FlatProject = Partial<
  OrderProject & {
    'user.id': OrderProject['user'] extends undefined
      ? undefined
      : NonNullable<OrderProject['user']>['id'];
  }
>;

const defineAbilityFor = (user: AccountData): AppAbility =>
  defineAbility<AppAbility>((can) => {
    can<FlatProject>(['update', 'delete'], 'OrderProject', {
      'user.id': user.id,
    });

    can<Partial<ProjectProductMarkup>>(['update', 'delete'], 'ProjectProductMarkup', {
      userId: user.id,
    });

    can('read', 'Payouts');

    if (user.role === UserRoleEnum.Supervisor) {
      can(['update', 'delete'], 'OrderProject');
      can(['update', 'delete'], 'ProjectProductMarkup');
      can('read', 'Users');
      can('read', 'Products');
      can('read', 'Clients');
      can('update', 'Payments');
      can(['update', 'read', 'create'], 'Payouts');
    }
  });

export default defineAbilityFor;
