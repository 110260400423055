import { Link as MuiLink, ListItemText, List, ListItem } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { currencyFormatter, dateFormatter } from 'core/utils/commonUtils';
import { currencyCol } from 'core/utils/muiGridUtils';
import { getOrderPayoutTypeName } from 'core/utils/orderUtils';
import usePayouts from 'hooks/swr/usePayouts';
import useResponsive from 'hooks/useResponsive';
import CardedPage from 'layout/pages/CardedPage';
import isNil from 'lodash-es/isNil';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import PayoutsPageHeader from './PayoutsPageHeader';
import type { PayoutsFilterData } from './PayoutsPageHeader';
import type { GridColDef } from '@mui/x-data-grid';
import type { OrderPayoutInfo } from 'models/order/orderPayoutInfo';

export default function PayoutsPage(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();
  const isSmScreen = useResponsive('down', 'sm');
  const [filters, setFilters] = useState({
    pagination: {
      page: +(searchParams.get('pageIndex') ?? 0),
      pageSize: +(searchParams.get('pageSize') ?? 25),
    },
    searchText: searchParams.get('searchText') ?? '',
    fromDate: searchParams.get('from') ? new Date(searchParams.get('from') ?? '') : null,
    toDate: searchParams.get('to') ? new Date(searchParams.get('to') ?? '') : null,
  });

  useEffect(() => {
    setSearchParams({
      pageIndex: String(filters.pagination.page),
      pageSize: String(filters.pagination.pageSize),
      searchText: filters.searchText,
      from: filters.fromDate?.toISOString() ?? '',
      to: filters.toDate?.toISOString() ?? '',
    });
  }, [setSearchParams, filters]);

  const { data: payouts, isLoading } = usePayouts({
    pageIndex: filters.pagination.page,
    pageSize: filters.pagination.pageSize,
    filterText: filters.searchText,
    from: filters.fromDate ?? undefined,
    to: filters.toDate ?? undefined,
  });

  const filterChangeHandler = (data: PayoutsFilterData): void => {
    setFilters({ ...filters, ...data, searchText: data.filterText ?? '' });
  };

  const title = 'Выплаты';

  const columns: Array<GridColDef<OrderPayoutInfo>> = [
    {
      field: 'dateCreate',
      headerName: 'Дата создания',
      type: 'date',
      minWidth: 120,
    },
    {
      field: 'datePayout',
      headerName: 'Дата выплаты',
      type: 'date',
      minWidth: 120,
    },
    {
      field: 'sum',
      headerName: 'Сумма',
      ...currencyCol,
    },
    {
      field: 'type',
      headerName: 'Тип',
      valueGetter: (value) => getOrderPayoutTypeName(value),
      flex: 0.4,
    },
    {
      field: 'order',
      headerName: 'Заказ',
      flex: 0.7,
      renderCell: (params) => (
        <MuiLink
          component={RouterLink}
          to={`/orders/${String(params.row.order.id)}/common`}>
          {`${String(params.row.order.number)} от ${dateFormatter.format(
            params.row.order.dateCreate
          )}`}
        </MuiLink>
      ),
    },
    {
      field: 'userCreated',
      headerName: 'Создал',
      flex: 0.8,
      valueGetter: (_, row) => row.userCreated?.fullName ?? '',
    },
    {
      field: 'userPayout',
      headerName: 'Получатель',
      flex: 0.8,
      valueGetter: (_, row) => row.userPayout?.fullName ?? '',
    },
    {
      field: 'remark',
      headerName: 'Примечание',
      flex: 1,
    },
  ];

  const mobileView = (
    <List disablePadding>
      {payouts?.items.map((payout) => (
        <ListItem key={payout.id} divider>
          <ListItemText
            primary={`${currencyFormatter.format(
              Number(payout.sum)
            )} от ${dateFormatter.format(payout.dateCreate)}`}
            secondary={`${getOrderPayoutTypeName(payout.type)}. ${
              !isNil(payout.userPayout) ? `${String(payout.userPayout?.fullName)}. ` : ''
            }${String(payout?.remark)}`}
          />
        </ListItem>
      ))}
    </List>
  );

  return (
    <CardedPage
      title={title}
      disablePadding
      header={
        <PayoutsPageHeader
          title={title}
          onFilterChanged={filterChangeHandler}
          filterTextInit={filters.searchText}
          fromDateInit={filters.fromDate}
          toDateInit={filters.toDate}
        />
      }>
      {isSmScreen ? (
        mobileView
      ) : (
        <DataGrid
          rows={payouts?.items ?? []}
          columns={columns}
          loading={isLoading}
          autoHeight
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          disableColumnSorting
          paginationModel={filters.pagination}
          onPaginationModelChange={(model) => {
            setFilters({ ...filters, pagination: model });
          }}
          paginationMode='server'
          rowCount={payouts?.totalCount ?? 0}
        />
      )}
    </CardedPage>
  );
}
