import { isNumber, isString } from 'lodash';
import { ClientDocumentTypeEnum } from 'models/enums/clientDocumentTypeEnum';
import { ClientTypeEnum } from 'models/enums/clientTypeEnum';
import { OrderActionEnum } from 'models/enums/orderActionEnum';
import { OrderPaymentTypeEnum } from 'models/enums/orderPaymentTypeEnum';
import { OrderPayoutTypeEnum } from 'models/enums/orderPayoutTypeEnum';
import { OrderStateEnum } from 'models/enums/orderStateEnum';
import type { GeolocationCoordinates, Order } from 'models/order/order';

/**
 * Возвращает название действия заказа по его типу.
 *
 * @param {OrderActionEnum} action Тип действия заказа.
 * @returns {string} Название действия заказа.
 */
export function getOrderActionName(action: OrderActionEnum): string {
  switch (action) {
    case OrderActionEnum.Request:
      return 'Заявка';

    case OrderActionEnum.Order:
      return 'Заказ';

    case OrderActionEnum.Measurement:
      return 'Замер';

    case OrderActionEnum.Monitoring:
      return 'Контроль';

    case OrderActionEnum.Installation:
      return 'Установка';

    case OrderActionEnum.Completion:
      return 'Завершение';

    default:
      return `ಠ╭╮ಠ`;
  }
}

/**
 * Возвращает название состояния заказа по его типу.
 *
 * @param {OrderActionEnum} action Тип действия заказа.
 * @returns {string} Название действия заказа.
 */
export function getOrderStateName(action: OrderStateEnum): string {
  switch (action) {
    case OrderStateEnum.Request:
      return 'Заявка';

    case OrderStateEnum.Order:
      return 'Заказ';

    case OrderStateEnum.Archive:
      return 'Архив';

    default:
      return `ಠ╭╮ಠ`;
  }
}

/**
 * Возвращает название типа оплаты заказа по его типу.
 *
 * @param {OrderPaymentTypeEnum} type Тип оплаты заказа.
 * @returns {string} Название типа оплаты заказа.
 */
export function getOrderPaymentTypeName(type: OrderPaymentTypeEnum): string {
  switch (type) {
    case OrderPaymentTypeEnum.Cash:
      return 'Наличный';

    case OrderPaymentTypeEnum.NonCash:
      return 'Безналичный';

    default:
      return `ಠ╭╮ಠ`;
  }
}

/**
 * Возвращает название типа выплаты заказа по его типу.
 *
 * @param {OrderPayoutTypeEnum} type Тип выплаты по заказу.
 * @returns {string} Название типа выплаты заказа.
 */
export function getOrderPayoutTypeName(type: OrderPayoutTypeEnum): string {
  switch (type) {
    case OrderPayoutTypeEnum.Cash:
      return 'Наличные';
    case OrderPayoutTypeEnum.NonCash:
      return 'Безналичный расчет';
    default:
      return 'Неизвестный тип';
  }
}

/**
 * Преобразует объект геолокации в строку.
 *
 * @param {GeolocationCoordinates} geolocation - Объект геолокации.
 * @returns {string} Строка геолокации в формате "широта, долгота".
 */
export const getGeolocationString = (geolocation: GeolocationCoordinates): string =>
  `${geolocation.latitude.toString()}, ${geolocation.longitude.toString()}`;

/**
 * Преобразует строку геолокации в объект геолокации.
 *
 * @param {string} geolocation - Строка геолокации в формате "широта, долгота".
 * @returns {GeolocationCoordinates} Объект геолокации.
 */
export const parseGeolocationString = (geolocation: string): GeolocationCoordinates => {
  const geolocationRegExp =
    /^(-?\d{1,2}(\.\d+)?)(([,;][ \t])|[\t ]|[,;])(-?\d{1,3}(\.\d+)?)$/;

  const matchGroups = geolocation?.match(geolocationRegExp);
  const latitude = parseFloat(matchGroups?.[1] ?? '');
  const longitude = parseFloat(matchGroups?.[5] ?? '');

  if (
    !isNumber(latitude) ||
    !isNumber(longitude) ||
    isNaN(latitude) ||
    isNaN(longitude) ||
    !isFinite(latitude) ||
    !isFinite(longitude)
  ) {
    throw new Error('Неверный формат координат');
  }

  return { latitude, longitude };
};

/**
 * Генерирует ссылку на карту Yandex по указанным координатам.
 *
 * @param {string | GeolocationCoordinates} geolocation - Строка с координатами или объект с координатами.
 * @returns {string} - Ссылка на карту Yandex с указанными координатами.
 */
export const getGeolocationLink = (
  geolocation: string | GeolocationCoordinates
): string => {
  let geo: GeolocationCoordinates;

  try {
    if (isString(geolocation)) {
      geo = parseGeolocationString(geolocation);
    } else {
      geo = geolocation;
    }
  } catch {
    return '';
  }

  return `https://maps.yandex.ru/?ll=${geo.longitude},${geo.latitude}&z=13&pt=${geo.longitude},${geo.latitude}`;
};

/**
 * Генерирует ссылку на виджет карты Yandex по указанным координатам.
 *
 * @param {string | GeolocationCoordinates} geolocation - Строка с координатами или объект с координатами.
 * @returns {string} - Ссылка на виджет карты Yandex с указанными координатами.
 */
export const getGeolocationWidgetLink = (
  geolocation: string | GeolocationCoordinates
): string => {
  let geo: GeolocationCoordinates;

  try {
    if (isString(geolocation)) {
      geo = parseGeolocationString(geolocation);
    } else {
      geo = geolocation;
    }
  } catch {
    return '';
  }

  return `https://yandex.ru/map-widget/v1?ll=${geo.longitude},${geo.latitude}&z=13&pt=${geo.longitude},${geo.latitude}&scroll=false`;
};

/**
 * Возвращает объект заказа с параметрами по умолчанию.
 *
 * @function
 * @returns {Order} Объект заказа с параметрами по умолчанию.
 */
export const getDefaultOrder = (): Order => ({
  address: '',
  dateCreate: new Date(),
  id: 0,
  number: '',
  remark: '',
  warningComment: '',
  sum: 0,
  sumPayments: 0,
  state: OrderStateEnum.Request,
  action: OrderActionEnum.Request,
  client: {
    email: '',
    id: 0,
    fullName: '',
    name: '',
    surname: '',
    patronymic: '',
    phone: '',
    type: ClientTypeEnum.Individual,
    registrationAddress: '',
    userId: 0,
    docIssuedBy: '',
    docNumber: '',
    docSeries: '',
    docType: ClientDocumentTypeEnum.Passport,

    entityAddress: '',
    inn: '',
    kpp: '',
    ogrn: '',
    paymentAccount: '',
    managementFullName: '',
    bankBIC: '',
    bankName: '',
    bankINN: '',
    bankKPP: '',
    bankCorrespondentAccount: '',
  },
});
